import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import '../assets/styles/Menu.css'; // Ensure this CSS file is correctly linked

const MenuItems = ({ closeDrawer }) => {
    const handleMenuClick = (e) => {
        if (closeDrawer) {
            closeDrawer();
        }
    };

    return (
        <>
            <Menu.Item key="1" onClick={handleMenuClick}>
                <Link to="/" className="menu-link">HOME</Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={handleMenuClick}>
                <Link to="/challenges" className="menu-link">CHALLENGES</Link>
            </Menu.Item>
            <Menu.Item key="3" onClick={handleMenuClick}>
                <Link to="/projects" className="menu-link">PROJECTS</Link>
            </Menu.Item>
            <Menu.Item key="4" onClick={handleMenuClick}>
                <Link to="/learning" className="menu-link">LEARNING</Link>
            </Menu.Item>
            <Menu.Item key="5" onClick={handleMenuClick}>
                <Link to="/mentorship" className="menu-link">MENTORSHIP</Link>
            </Menu.Item>
            <Menu.Item key="6" onClick={handleMenuClick}>
                <Link to="/community" className="menu-link">COMMUNITY</Link>
            </Menu.Item>
            <Menu.Item key="7" onClick={handleMenuClick}>
                <Link to="/assessment" className="menu-link">ASSESSMENT</Link>
            </Menu.Item>
            <Menu.Item key="8" onClick={handleMenuClick}>
                <Link to="/courses" className="menu-link">COURSES</Link>
            </Menu.Item>
            <Menu.Item key="9" onClick={handleMenuClick}>
                <Link to="/news" className="menu-link">NEWS</Link>
            </Menu.Item>
            <Menu.Item key="10" onClick={handleMenuClick} className="menu-item-button">
                <Link
                    to="/signin"
                    className="menu-link rounded-button login-button"
                >
                    LOGIN
                </Link>
            </Menu.Item>
            <Menu.Item key="11" onClick={handleMenuClick} className="menu-item-button">
                <Link
                    to="/signup"
                    className="menu-link rounded-button signup-button"
                >
                    SIGN UP
                </Link>
            </Menu.Item>
        </>
    );
};

export default MenuItems;