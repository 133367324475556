import React from 'react';
import { Row, Col, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import '../assets/styles/Footer.css'; // Ensure this CSS file is correctly linked

const { Footer } = Layout;
const { Title, Text } = Typography;

const FooterComponent = () => (
    <div>
        <Footer className="footer">
            <div className="footer-content">
                <Row gutter={[16, 24]} justify="start">
                    {/* About JectHub Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">About JectHub</Title>
                        <ul className="footer-links">
                            <li><Link to="/why-jecthub" className="footer-link">Why JectHub</Link></li>
                            <li><Link to="/our-ai-team" className="footer-link">JectHub Team</Link></li>
                            <li><Link to="/mentors" className="footer-link">World Mentors</Link></li>
                            <li><Link to="/mission" className="footer-link">Mission Focused</Link></li>
                            <li><Link to="/newsroom" className="footer-link">Newsroom</Link></li>
                            <li><Link to="/ai-status" className="footer-link">AI System Status</Link></li>
                            <li><Link to="/trust-safety" className="footer-link">AI Trust & Safety</Link></li>
                            <li><Link to="/untapped-talent" className="footer-link">Untapped Talent Markets</Link></li>
                            <li><Link to="/talent-community" className="footer-link">Our Talent Community</Link></li>
                            <li><Link to="/customer-stories" className="footer-link">Customer Stories</Link></li>
                            <li><Link to="/tei-study" className="footer-link">Forrester Total Economic Impact (TEI) Study</Link></li>
                        </ul>
                    </Col>

                    {/* Solutions Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Solutions</Title>
                        <ul className="footer-links">
                            <li><Link to="/engagement-models" className="footer-link">Flexible Engagement Models</Link></li>
                            <li><Link to="/application-development" className="footer-link">Application Development</Link></li>
                            <li><Link to="/data-science-ai" className="footer-link">Data Science & AI</Link></li>
                            <li><Link to="/data-engineering-analytics" className="footer-link">Data Engineering & Analytics</Link></li>
                            <li><Link to="/cloud-devops" className="footer-link">Cloud & DevOps</Link></li>
                            <li><Link to="/custom-software" className="footer-link">Custom Software Development</Link></li>
                            <li><Link to="/legacy-modernization" className="footer-link">Legacy System Modernization</Link></li>
                            <li><Link to="/genai-engagement" className="footer-link">GenAI Engagement Model</Link></li>
                            <li><Link to="/cloud-migrations" className="footer-link">Cloud Migrations</Link></li>
                            <li><Link to="/cyber-threat-detection" className="footer-link">Threat Detection</Link></li>
                        </ul>
                    </Col>

                    {/* Platform Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Platform</Title>
                        <ul className="footer-links">
                            <li><Link to="/platform-overview" className="footer-link">Platform Overview</Link></li>
                            <li><Link to="/integrations" className="footer-link">Integrations</Link></li>
                            <li><Link to="/product" className="footer-link">Product</Link></li>
                            <li>
                                <a href="https://developer.jecthub.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                                    JectHub DevPay Portal
                                </a>
                            </li>
                            <li><Link to="/qualified-by-jecthub" className="footer-link">Qualified by JectHub</Link></li>
                            <li><Link to="/resource-center" className="footer-link">Resource Center</Link></li>
                            <li><Link to="/case-studies" className="footer-link">Case Studies</Link></li>
                            <li><Link to="/ebooks" className="footer-link">Ebooks</Link></li>
                            <li><Link to="/humans-of-jecthub" className="footer-link">Humans of JectHub</Link></li>
                            <li><Link to="/profiles-in-brilliance" className="footer-link">Profiles in Brilliance</Link></li>
                        </ul>
                    </Col>

                    {/* For Talent Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">For Talent</Title>
                        <ul className="footer-links">
                            <li><Link to="/why-join-jecthub" className="footer-link">Why Join JectHub</Link></li>
                            <li><Link to="/our-process" className="footer-link">Our Process</Link></li>
                            <li><Link to="/find-opportunities" className="footer-link">Find Opportunities</Link></li>
                            <li><Link to="/talent-experience" className="footer-link">Talent Experience</Link></li>
                            <li><Link to="/perks" className="footer-link">Perks</Link></li>
                            <li><Link to="/codewars" className="footer-link">Codewars</Link></li>
                            <li><Link to="/help-center" className="footer-link">Help Center</Link></li>
                            <li><Link to="/leadership" className="footer-link">Leadership</Link></li>
                            <li><Link to="/sustainability" className="footer-link">Sustainability</Link></li>
                            <li><Link to="/careers" className="footer-link">Careers</Link></li>
                        </ul>
                    </Col>

                    {/* Students Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Students</Title>
                        <ul className="footer-links">
                            <li><Link to="/student-dashboard" className="footer-link">Student Dashboard</Link></li>
                            <li><Link to="/free-courses" className="footer-link">Free AI & Cybersecurity Courses</Link></li>
                            <li><Link to="/ai-pricing" className="footer-link">AI Learning Plans</Link></li>
                            <li><Link to="/mentorship-programs" className="footer-link">Mentorship Programs</Link></li>
                            <li><Link to="/internships" className="footer-link">Internship Opportunities</Link></li>
                            <li><Link to="/certifications" className="footer-link">Get Certified</Link></li>
                            <li><Link to="/student-community" className="footer-link">Join Student Community</Link></li>
                            <li><Link to="/ai-demo" className="footer-link">Request AI Demo</Link></li>
                            <li><Link to="/ai-support" className="footer-link">AI Product Support</Link></li>
                            <li><Link to="/ai-developers" className="footer-link">AI for Developers</Link></li>
                        </ul>
                    </Col>

                    {/* Vendors Section */}
                    <Col xs={24} sm={12} md={4} style={{ paddingLeft: '20px' }}>
                        <Title level={5} className="footer-title">Vendors</Title>
                        <ul className="footer-links">
                            <li><Link to="/premium-services" className="footer-link">Premium Services</Link></li>
                            <li><Link to="/exclusive-content" className="footer-link">Exclusive Content</Link></li>
                            <li><Link to="/advanced-courses" className="footer-link">Advanced Courses</Link></li>
                            <li><Link to="/sell-your-themes" className="footer-link">Sell Your Themes</Link></li>
                            <li><Link to="/sell-ai-products" className="footer-link">Sell AI Products</Link></li>
                            <li><Link to="/internships" className="footer-link">Internship Opportunities</Link></li>
                            <li><Link to="/mentorship-programs" className="footer-link">Mentorship Programs</Link></li>
                            <li><Link to="/vendor-partnerships" className="footer-link">Vendor Partnerships</Link></li>
                            <li><Link to="/api-access" className="footer-link">API Access</Link></li>
                            <li><Link to="/developer-resources" className="footer-link">Developer Resources</Link></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </Footer>

        <div className="footer-bottom">
            <div className="footer-bottom-content">
                <Text className="footer-text">© 2024 JectHub - Empowering the Future with AI.</Text>
                <div className="footer-social">
                    <a href="https://www.facebook.com/jecthub" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                    </a>
                    <a href="https://x.com/JectHub" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaTwitter />
                    </a>
                    <a href="https://www.linkedin.com/company/jecthub/" className="footer-social-link" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn />
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default FooterComponent;